import type { BannerItem as HomeMiddleBanner } from '@/types'
import Link from 'next/link'
import { useMemo, type FC } from 'react'
import ImageOptimize from '../ImageOptimize'

interface Props {
  banner: Array<HomeMiddleBanner>
  layout?: number | null
}
export const HomeBanner: FC<Props> = ({ banner, layout }: Props) => {
  const layoutOne = useMemo(() => {
    return (
      <div className='grid grid-cols-2 gap-[14px]'>
        {banner &&
          banner.slice(0, 1).map((e, index) => (
            <div className='w-full' key={index}>
              <Link
                href={e.link || '/'}
                className='rounded overflow-hidden block outline-none h-full w-full'
              >
                <ImageOptimize
                  width={196}
                  height={299}
                  src={e.image}
                  alt={e.title}
                  className='w-full object-cover h-full'
                />
              </Link>
            </div>
          ))}
        <div className={`grid grid-cols-1 gap-[14px]`}>
          {banner &&
            banner.slice(1, 4).map((e, index) => (
              <div className='block' key={index}>
                <Link
                  href={e.link || '/'}
                  className='rounded overflow-hidden block outline-none h-full w-full'
                >
                  <ImageOptimize
                    width={196}
                    height={92}
                    src={e.image}
                    alt={e.title}
                    className='w-full object-cover h-full'
                  />
                </Link>
              </div>
            ))}
        </div>
      </div>
    )
  }, [layout])
  const layoutTwo = useMemo(() => {
    return (
      <div className='grid grid-cols-2 gap-[14px]'>
        {banner &&
          banner.map((e, index) => (
            <div className='w-full' key={index}>
              <Link
                href={e.link || '/'}
                className='rounded overflow-hidden block outline-none h-full w-full'
              >
                <ImageOptimize
                  width={196}
                  height={299}
                  src={e.image}
                  alt={e.title}
                  className='w-full object-cover h-full'
                />
              </Link>
            </div>
          ))}
      </div>
    )
  }, [layout])
  const layoutThree = useMemo(() => {
    return (
      <div className='w-full flex flex-wrap'>
        <div className='w-full mb-[14px]'>
          {banner &&
            banner.slice(0, 1).map((e, index) => (
              <div className='w-full' key={index}>
                <Link
                  href={e.link || '/'}
                  className='rounded overflow-hidden block outline-none h-full w-full'
                >
                  <ImageOptimize
                    width={196}
                    height={299}
                    src={e.image}
                    alt={e.title}
                    className='w-full object-cover h-full'
                  />
                </Link>
              </div>
            ))}
        </div>
        <div className={`grid grid-cols-3 gap-[14px] w-full`}>
          {banner &&
            banner.slice(1, 4).map((e, index) => (
              <Link
                href={e.link || '/'}
                className='rounded overflow-hidden block outline-none h-full w-full'
                key={index}
              >
                <ImageOptimize
                  width={196}
                  height={92}
                  src={e.image}
                  alt={e.title}
                  className='w-full object-cover h-full'
                />
              </Link>
            ))}
        </div>
      </div>
    )
  }, [layout])
  const layoutFour = useMemo(() => {
    return (
      <div className='w-full flex flex-wrap'>
        <div className={`grid grid-cols-3 gap-2 w-full`}>
          {banner &&
            banner.slice(0, 3).map((e, index) => (
              <Link
                href={e.link || '/'}
                className='rounded overflow-hidden block outline-none h-full w-full'
                key={index}
              >
                <ImageOptimize
                  width={196}
                  height={92}
                  src={e.image}
                  alt={e.title}
                  className='w-full object-cover h-full'
                />
              </Link>
            ))}
        </div>
      </div>
    )
  }, [layout])
  const layoutFive = useMemo(() => {
    return (
      <div className={`flex w-full items-end`}>
        {banner &&
          banner.slice(0, 3).map((e, index) => (
            <Link
              href={e.link || '/'}
              className={`rounded overflow-hidden block outline-none h-full w-full ${
                index === 0 || index === 2
                  ? 'max-w-[27%] flex-[0_0_27%]'
                  : 'max-w-[46%] flex-[0_0_46%] px-2'
              }`}
              key={index}
            >
              <ImageOptimize
                width={196}
                height={92}
                src={e.image}
                alt={e.title}
                className='w-full h-auto'
              />
            </Link>
          ))}
      </div>
    )
  }, [layout])
  const checkLayoutBanner = () => {
    switch (layout) {
      case 1:
        return layoutOne
        break
      case 2:
        return layoutTwo
        break
      case 3:
        return layoutThree
        break
      case 4:
        return layoutFour
        break
      case 5:
        return layoutFive
        break
      default:
        return layoutOne
        break
    }
  }
  return (
    <>
      {banner && (
        <div className={`mb-3 ${layout != 5 ? 'container' : ''}`}>
          {checkLayoutBanner()}
        </div>
      )}
    </>
  )
}
