import type { BannerItem as HomeSliderType } from '@/types'
import Link from 'next/link'
import { useRef, type FC } from 'react'
import type { Settings } from 'react-slick'
import Slider from 'react-slick'
import ImageOptimize from '../ImageOptimize'

interface Props {
  sliders: Array<HomeSliderType>
}

export const HomeSlider: FC<Props> = ({ sliders }: Props) => {
  const sliderSettings: Settings = {
    autoplay: true,
    dots: true,
    autoplaySpeed: 3000,
    arrows: false,
    fade: true,
    speed: 500,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  }
  const ref = useRef<Slider>(null)

  return (
    <section
      className={`homeSlider mb-3.5 overflow-hidden w-full ${
        !sliders ? 'hidden' : ''
      }`}
    >
      {sliders ? (
        <Slider
          ref={ref}
          {...sliderSettings}
          className='max-w-screen slickHome flex'
        >
          {sliders &&
            sliders.map((slider: HomeSliderType, index: number) => (
              <Link href={slider?.link || '/'} key={index}>
                <ImageOptimize
                  className='w-full h-auto'
                  src={slider?.image}
                  alt={slider?.title}
                  width={1312}
                  height={480}
                  priority
                />
              </Link>
            ))}
        </Slider>
      ) : (
        ''
      )}
    </section>
  )
}
