import { MenuItemType, PageTypeSuffix } from '@/constants'
import { type Menu } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Link from 'next/link'
import 'react-loading-skeleton/dist/skeleton.css'
import type { Settings } from 'react-slick'
import Slider from 'react-slick'
import ImageOptimize from '../ImageOptimize'
interface QuickMenuProps {
  QuickMenuData: Menu[]
}

const QuickMenu = ({ QuickMenuData }: QuickMenuProps) => {
  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    rows: 2,
    centerPadding: '5px',
  }
  const settingsOneRow: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    centerPadding: '5px',
  }
  const data = QuickMenuData?.sort((a, b) => a.order - b.order)
  return (
    <>
      {data ? (
        <div className={`mb-6 container`} id='menuBoxScroll'>
          {data.length <= 5 && (
            <Slider {...settingsOneRow} className='slickCate slickHome'>
              {data.map((item, index) => {
                return (
                  <Link
                    href={
                      item.type === MenuItemType.PRODUCT_CAT
                        ? `/${item.slug + PageTypeSuffix.CATEGORY}`
                        : item.url || '/'
                    }
                    key={index}
                    className='!flex flex-wrap justify-center pr-1 mr-1 outline-none mb-1'
                  >
                    <ImageOptimize
                      src={item.icon}
                      alt={getTranslate(item.name)}
                      width={80}
                      height={80}
                      className='object-contain aspect-square'
                    />

                    <span className='text-dark line-clamp-2 h-[28px] font-bold text-center block overflow-hidden text-ellipsis text-textSmall text-dark-400'>
                      {getTranslate(item.name)}
                    </span>
                  </Link>
                )
              })}
            </Slider>
          )}
          {data.length > 5 && data.length <= 10 && (
            <div className='grid grid-cols-5 gap-1.5'>
              {data.map((item, index) => {
                return (
                  <Link
                    href={
                      item.type === MenuItemType.PRODUCT_CAT
                        ? `/${item.slug + PageTypeSuffix.CATEGORY}`
                        : item.url || '/'
                    }
                    key={index}
                    className='!flex flex-wrap justify-center outline-none'
                  >
                    <ImageOptimize
                      src={item.icon}
                      alt={getTranslate(item.name)}
                      width={80}
                      height={80}
                      className='object-contain aspect-square mb-1'
                    />

                    <span className='text-dark line-clamp-2 h-[28px] font-bold text-center block overflow-hidden text-ellipsis text-textSmall text-dark-400'>
                      {getTranslate(item.name)}
                    </span>
                  </Link>
                )
              })}
            </div>
          )}
          {data.length > 10 && (
            <Slider {...settings} className='slickCate slickHome'>
              {data.map((item, index) => {
                return (
                  <Link
                    href={
                      item.type === MenuItemType.PRODUCT_CAT
                        ? `/${item.slug + PageTypeSuffix.CATEGORY}`
                        : item.url || '/'
                    }
                    key={index}
                    className='!flex flex-wrap justify-center outline-none mb-1 pr-1 mr-1'
                  >
                    <ImageOptimize
                      src={item.icon}
                      alt={getTranslate(item.name)}
                      width={80}
                      height={80}
                      className='object-contain aspect-square'
                    />

                    <span className='text-dark line-clamp-2 h-[28px] font-bold text-center block overflow-hidden text-ellipsis text-textSmall text-dark-400'>
                      {getTranslate(item.name)}
                    </span>
                  </Link>
                )
              })}
            </Slider>
          )}
        </div>
      ) : (
        ''
      )}
    </>
  )
}
export default QuickMenu
